import React, { useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next'
import { useForm } from "react-hook-form";
import Button from '@mui/joy/Button';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector, useDispatch } from "react-redux";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import ShippingAddressModal from "./ShippingAddressModal";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Loader from '../Loader';
import { useCancelAddressMutation } from '../../services/login';

function ShippingAddress({ ShippingInfo, getSelectShipmentAddressId, setSelectShipmentAddressId }) {
  const { userId, userToken, userEmail, userName, languageCode, countryCode, cartToken, guestShippment, guestPayment, isPurchase } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const { t } = useTranslation()


  const ShippingData = ShippingInfo?.data?.shipping_addresses
  const [seleteaddress, deleteResult] = useCancelAddressMutation()


  const [value, setValue] = React.useState(false);
  const [isModal, setModal] = React.useState(false);

  function openShippingAddressModal() {
    setModal(true)
  }
  function closeShippingAddressModal() {
    setModal(false)
  }

  function makeShippingAddessDefault(data) {
    setSelectShipmentAddressId(data)
  }
  useEffect(() => {
    if (!getSelectShipmentAddressId) {
      setSelectShipmentAddressId(ShippingData?.[0]?.id)
    }
  }, [ShippingData])

  function maximumCount(lenght) {
    if (length === 0) {
      return true
    } else if (lenght < 5) {
      return true
    } else {
      return false
    }
  }

  return (
    <>
      <ShippingAddressModal isModal={isModal} closeShippingAddressModal={closeShippingAddressModal} />

      <div className="col-md-12 pp-padding">
        <div className='cp-cinfo-card ' style={{ marginBottom: '15px' }}>

          <div style={{ paddingBottom: '15px' }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap' }}>
              <div className='alert alert-info alert-text' style={{ marginRight: '10px' }}>
                {t('select_shipping_address')}
              </div>
              {!ShippingInfo?.isLoading &&
                <>
                  {maximumCount(ShippingData?.length) &&
                    <Button
                      size='sm'
                      variant='outlined'
                      style={{ fontWeight: 400, minHeight: '27px', maxHeight: '27px', color: '#5d8e3e', border: '1px solid #5d8e3e' }}
                      onClick={(() => openShippingAddressModal())}>
                      {ShippingData?.length > 0 ?
                        <small>{t('Add New')}</small> :
                        <small>{t('Add')}</small>
                      }
                    </Button>
                  }
                </>
              }
            </div>

            {!ShippingInfo?.isLoading &&
              <>
                {ShippingData?.length === 0 &&
                  <small className="alert alert-danger" style={{ padding: 0, margin: 0, background: '#fff0', border: 'none' }}>
                    Add shipping address
                  </small>
                }
              </>
            }
            {ShippingInfo?.isLoading &&
              <div style={{ marginBottom: '10px' }}>
                <Skeleton variant="rect" height={'15px'} />
                <Skeleton variant="rect" height={'15px'} />
              </div>
            }
          </div>
          {ShippingData?.map((item, i) =>
            <div className="col-md-12" style={{ marginBottom: '15px', padding: '0px', position: 'relative' }} key={i}>
              <div
                className="cp-shipping-card"
                onClick={() => makeShippingAddessDefault(item.id)}
              >
                <RadioGroup value={value} style={{ paddingRight: '10px' }}>
                  <FormControlLabel
                    style={{ margin: 0 }}
                    value={true}
                    control={<Radio checked={getSelectShipmentAddressId === item.id} />} />
                </RadioGroup>
                <div className="cart-page-text">
                  <div style={{ width: '100%', fontSize: '14px' }}>
                    {/* <div>{item.name}</div> */}
                    <div>{item.address}, {item.street}, {item.city}, {item.country_code}</div>
                    {/* <div>{item.phone}</div> */}
                  </div>
                </div>
              </div>
              {getSelectShipmentAddressId !== item.id &&
                <div style={{ position: 'absolute', top: '0px', right: '0px', zIndex: 1 }}>
                  <Button
                    disabled={deleteResult?.isLoading && deleteResult?.originalArgs?.id === item.id}
                    onClick={(() => seleteaddress({
                      id: item.id,
                      userId: userId,
                      userToken: userToken,
                      countryCode: countryCode
                    }))}
                    style={{ minHeight: '22px', maxHeight: '22px', borderRadius: '4px' }}
                    variant='plain'
                    size='sm'
                    color='danger'
                  >
                    {deleteResult?.isLoading && deleteResult?.originalArgs?.id === item.id ?
                      <Loader /> :
                      <CloseIcon style={{ fontSize: '14px' }} />
                    }
                  </Button>
                </div>
              }
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default ShippingAddress;
