import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import "antd/dist/antd.min.css";
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from "react-redux";
import 'react-phone-number-input/style.css'
import formatPrice from '../formatPrice';
import Loader from '../Loader';
import Button from '@mui/joy/Button';
import Host from '../Host';

function CartIInformation({ isLoading, isFetching, cartData, userData, couponData, proceedeToCheckout, isSavedCardSelected, isApplePaySelected, isPayByCardSelected, isTabbySelected, getValidInput, checkoutResult, isShipmentAddress, getTestLoading }) {
  const { userId, userToken, userEmail, userName, languageCode, countryCode, cartToken, guestShippment, guestPayment } = useSelector((state) => state.auth);
  const { t } = useTranslation()
  const history = useHistory();
  const couponCode = new URLSearchParams(location.search).get('coupon') || null
  const [getCoupon, setCoupon] = useState('')

  const TotalPrice = cartData?.reduce((accumulator, value) => {
    const productPrice = value.prices?.value * value.quantity;
    return accumulator + productPrice
  }, 0);


  function proceedButtonStatus() {
    if (isSavedCardSelected) {
      return false
    } else if (isApplePaySelected) {
      return false
    } else if (isTabbySelected) {
      return false
    } else if (getValidInput) {
      return false
    } else if (getValidInput) {
      return false
    } else if (isPayByCardSelected) {
      return false
    } else {
      return true
    }
  }


  let checkoutButtonDisabled = true
  if (isSavedCardSelected) {
    checkoutButtonDisabled = false
  } else if (isApplePaySelected) {
    checkoutButtonDisabled = false
  } else if (isTabbySelected) {
    checkoutButtonDisabled = false
  } else if (getValidInput) {
    checkoutButtonDisabled = false
  } else if (isPayByCardSelected) {
    checkoutButtonDisabled = false
  } else if (isPayByCardSelected) {
    checkoutButtonDisabled = false
  }

  function proceedButtonStatus(loading, status) {
    if (loading) {
      return true
    } else {
      return status
    }
  }

  const ppbutton = {
    height: '50px',
    background: proceedButtonStatus(checkoutResult?.isLoading, checkoutButtonDisabled) ? '#e8ebe3' : Host?.bgColor,
    color: Host?.buttonColor,
    width: '100%'
  }

  return (
    <>
      {cartData?.length > 0 &&
        <div className='ppbt pp-info-section'>
          <div className='ppbt' style={{ padding: '15px' }}>
            <div className='coupon-section' style={{ display: 'flex' }}>
              <div style={{ width: '100%' }}>
                <input
                  disabled={couponCode}
                  placeholder={couponCode ? couponCode : 'Coupon'}
                  className='form-control coupon-input'
                  style={{ height: '40px', borderRadius: 0 }}
                  onChange={(e) => setCoupon(e.target.value)}
                />
              </div>
              <Button
                variant='outlined'
                disabled={isLoading || isFetching || !couponCode && !getCoupon}
                style={{ height: '40px', borderRadius: 0, border: 'none' }}
                className='cp-buttons'
                onClick={(() =>
                  couponCode ?
                    history.push(`${window.location.pathname}`) :
                    history.push(`${window.location.pathname}?coupon=${getCoupon}`)
                )}
              >
                {isLoading || isFetching ?
                  <Loader /> :
                  couponCode ? 'Remove' : 'Apply'
                }
              </Button>
            </div>
            {couponData &&
              <>
                {couponData?.success && <small style={{ color: 'green' }}> {couponData?.success}</small>}
                {couponData?.error && <small style={{ color: 'tomato' }}> {couponData?.error}</small>}
              </>
            }
            <br />
            <div style={{ padding: '5px 0px 5px 0px' }}></div>
            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '10px' }}>
              <div><small>{t('sub_total')}</small></div>
              <div>
                <small>{formatPrice(TotalPrice, cartData?.[0]?.prices?.currency, 0)}</small>
              </div>
            </div>
            {couponData?.success &&
              <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '10px' }}>
                <div><small>{t('discount')}</small></div>
                <div>
                  <small>-{formatPrice(couponData?.discount, cartData?.[0]?.prices?.currency, 0)}</small>
                </div>
              </div>
            }
            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '10px' }}>
              <div><small>{t('delivery')} {t('fee')}</small></div>
              <div>
                <small>{userData?.contant?.standard_delivery > 0 ?
                  formatPrice(0, userData?.contant?.currency, userData?.contant?.standard_delivery) :
                  t('free')}</small>
              </div>
            </div>
            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '10px' }}>
              <div><small><strong>{t('total')}</strong> {t('inclusive')} {`(of VAT)`}</small></div>
              <div>
                {formatPrice(couponData ? Number(TotalPrice) - Number(couponData?.discount) : TotalPrice, cartData?.[0]?.prices?.currency, userData?.contant?.standard_delivery)}
              </div>
            </div>
          </div>

          <div className='checkout-button-bottom'>
            <Button
              disabled={proceedButtonStatus(checkoutResult?.isLoading, checkoutButtonDisabled)}
              variant='soft'
              size='lg'
              style={ppbutton}
              onClick={(() => proceedeToCheckout())}
            >
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                <span style={{ marginRight: '10px' }}>{t('Proceed')}</span>
                <div>
                  {formatPrice(couponData ? Number(TotalPrice) - Number(couponData?.discount) : TotalPrice, cartData?.[0]?.prices?.currency, userData?.contant?.standard_delivery)}
                </div>
              </div>
            </Button>
          </div>

        </div>
      }
    </>
  );
}

export default CartIInformation;
